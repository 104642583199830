import _ from 'lodash'
import { NextSeo } from 'next-seo'

type SEOProps = {
	title?: string
	description?: string
	keywords?: string
	ogUrl?: string
	ogTitle?: string
	ogDescription?: string
	ogImage?: string
}

const SEO = (props: SEOProps) => {
	const openGraph = _.pickBy(
		{
			url: props.ogUrl,
			title: props.ogTitle,
			description: props.ogDescription,
			images: _.identity(props.ogImage) ? [{ url: props.ogImage }] : undefined,
		},
		_.identity,
	)
	const transformedProps = _.pickBy(
		{
			title: props.title,
			description: props.description,

			additionalMetaTags: _.identity(props.keywords)
				? [
						{
							name: 'keywords',
							content: props.keywords,
						},
				  ]
				: undefined,
			openGraph: _.isEmpty(openGraph) ? undefined : openGraph,
		},
		_.identity,
	)
	return <NextSeo {...transformedProps} />
}

export default SEO
