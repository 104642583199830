import { Container } from '@/components/Container'
import Landing from '@/components/Landing/adminjs/Landing'
import { SOLVOOK_WRITE_STUDIO } from '@/settings/constant'
import { Tabs } from '@bookips/solvook-ui-library'
import { GetServerSideProps } from 'next/types'
import { getLanding } from 'src/api/landing'

const LandingPage = ({ landing, error }) => {
	const { tabsProps } = Tabs.useTabs('about-expert')

	const handleClickTab = (_, key) => {
		if (key === 'my-papers') {
			window.location.href = SOLVOOK_WRITE_STUDIO
		}
	}

	if (!landing || error) return null
	return (
		<>
			<Container>
				<Tabs {...tabsProps} onChange={handleClickTab}>
					<Tabs.Tab label="엑스퍼트 소개" value="about-expert" />,
					<Tabs.Tab label="내가 만든 시험지 보기" value="my-papers" />,
				</Tabs>
			</Container>
			<Landing landing={landing} />
		</>
	)
}

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
	try {
		const landing = await getLanding('expert', `${query.slug}`)
		return {
			props: { landing },
		}
	} catch (error) {
		if (error.response.status === 404) {
			return {
				notFound: true,
			}
		}
		return {
			props: { error: error.response.data },
		}
	}
}

export default LandingPage
