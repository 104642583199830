import type { ConfirmDialogProps } from '@bookips/solvook-ui-library'
import { useCallback, useEffect, useState } from 'react'

export interface CustomConfirmDialogProps extends ConfirmDialogProps {}

const useDialog = (defaultValues?: CustomConfirmDialogProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const DEFAULT_DIALOG_PROPS = {
		onClose: () => setIsOpen(false),
		open: isOpen,
	}

	const [dialogProps, setDialogProps] = useState<CustomConfirmDialogProps>({
		...DEFAULT_DIALOG_PROPS,
		...defaultValues,
	})
	useEffect(() => {
		setDialogProps((prev) => ({ ...prev, open: isOpen }))
	}, [isOpen])

	const close = () => {
		setIsOpen(false)
	}

	const onOk = async () => {
		try {
			await dialogProps.onOk?.()
		} catch (error) {
			console.error(error)
		} finally {
			close()
		}
	}
	const onCancel = async () => {
		try {
			await dialogProps.onCancel?.()
		} catch (error) {
			console.error(error)
		} finally {
			close()
		}
	}

	const modifyDialog = useCallback((modifyProps: Partial<CustomConfirmDialogProps>) => {
		setDialogProps({ ...DEFAULT_DIALOG_PROPS, ...modifyProps })
	}, [])

	const open = useCallback(
		(updateData?: Partial<CustomConfirmDialogProps>) => {
			updateData && modifyDialog(updateData)
			setIsOpen(true)
		},
		[modifyDialog],
	)

	return {
		open,
		close,
		modifyDialog,
		isOpen,
		dialogProps: { ...dialogProps, onOk, onCancel },
	}
}

export default useDialog
