import CheckLoginDialog from '@/components/modal/CheckLoginDialog'
import useDialog, { CustomConfirmDialogProps } from '@/hooks/useDialog'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { ConfirmDialog } from '@bookips/solvook-ui-library'
import { styled } from '@mui/material'

import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { LandingDTO, LandingSectionItems, validateMembership } from 'src/api/landing'
import _ from 'lodash'
import SEO from '@/components/SEO'

type DialogMessages = {
	ready: Partial<CustomConfirmDialogProps>
	close: Partial<CustomConfirmDialogProps>
	passError: Partial<CustomConfirmDialogProps>
	passSuccess: Partial<CustomConfirmDialogProps>
}

type LandingProps = {
	landing: LandingDTO
}

const MIXPANEL_TYPE = {
	event: 'Event',
	expert: 'eXpert',
}

const Landing = ({ landing }: LandingProps) => {
	const { status, sectionItems } = landing

	const router = useRouter()
	const { isLoggedIn } = useAuthStore()
	const { open, dialogProps } = useDialog()
	const { device } = useWindowDimensions()
	const {
		isOpen: isLoginDialogOpen,
		open: openLoginDialog,
		close: closeLoginDialog,
	} = useDialog()

	const mixpanelBase = {
		'Page Type': MIXPANEL_TYPE[landing.type],
		'Page Id': landing.id,
		'Page Title': landing.ogTitle,
	}
	const dialogMessages: DialogMessages = {
		ready: {
			title: '곧 오픈 예정이에요',
			severity: 'caution',
			okText: '확인',
			onOk: () => router.push('/'),
			onClose: () => router.push('/'),
		},
		close: {
			title: '이미 종료되었어요.',
			severity: 'caution',
			okText: '확인',
			onOk: () => router.push('/'),
			onClose: () => router.push('/'),
		},
		passError: {
			title: '지급 대상이 아닙니다.',
			content: '자세한 내용은 유의사항을 확인해 주세요.',
			severity: 'caution',
			okText: '확인',
			onOk: function () {
				mixpanelEvent('[PR] Pop-up Button Clicked', {
					...mixpanelBase,
					'Popup Type': 'Issuance Restricted',
					'Popup Title': this.title,
					'Button Name': this.okText,
					'Button URL': 'null',
				})
			},
		},
		passSuccess: {
			content: '이용권 사용방법은 하단 유의사항을 참고하세요.',
			severity: 'success',
			okText: '사용하러 가기',
			cancelText: '확인',
		},
	}

	const handleSectionClick = async (section: LandingSectionItems) => {
		const mixpanelSection = {
			'Section Id': section.id,
			Url: section.imageLink || section.buttonLink,
			'Section Type': section.imageLink ? 'Image' : 'Pass',
		}

		if (section.imageLink) {
			mixpanelEvent('[PR] Landing Section Clicked', { ...mixpanelBase, ...mixpanelSection })
			window.location.href = section.imageLink
			return
		}

		if (section.buttonLink)
			await validatePass(section, (mixpanelProperties) => {
				mixpanelEvent('[PR] Landing Section Clicked', {
					...mixpanelBase,
					...mixpanelSection,
					...mixpanelProperties,
				})
			})
	}

	const validatePass = async (
		section: LandingSectionItems,
		onComplete: (mixpanelProperties) => void,
	): Promise<void> => {
		let mixpanelProperties = {}

		try {
			if (!isLoggedIn) throw new Error('Unauthorized')
			if (!section.id) throw new Error('Unavailable Section Id')

			const response = await validateMembership({ sectionId: section.id })
			mixpanelProperties = {
				'Pass Issuance Completed': 'Y',
				'Pass Id': response.membershipProductId,
			}
			open({
				...dialogMessages.passSuccess,
				title: `${response.membershipProductTitle}이 발급됐어요!`,
				onCancel: () => {
					mixpanelEvent('[PR] Pop-up Button Clicked', {
						...mixpanelBase,
						'Popup Type': 'Issuance Completed',
						'Popup Title': `${response.membershipProductTitle}이 발급됐어요!`,
						'Button Name': dialogMessages.passSuccess.cancelText,
						'Button URL': 'null',
					})
				},
				onOk: () => {
					mixpanelEvent('[PR] Pop-up Button Clicked', {
						...mixpanelBase,
						'Popup Type': 'Issuance Completed',
						'Popup Title': `${response.membershipProductTitle}이 발급됐어요!`,
						'Button Name': dialogMessages.passSuccess.okText,
						'Button URL': section.buttonLink,
					})
					window.location.href = section.buttonLink
				},
			})
		} catch (error) {
			console.error(error)

			mixpanelProperties['Pass Issuance Completed'] = 'N'
			if (error.message === 'Unauthorized') {
				mixpanelProperties['Reason'] = 'Not Logged In'
				openLoginDialog()
			} else {
				mixpanelProperties['Reason'] = 'Unavailable'
				open(dialogMessages.passError)
			}
		} finally {
			onComplete(mixpanelProperties)
		}
	}
	const metadata = Object.assign(
		_.pick(landing, ['ogUrl', 'ogTitle', 'keywords', 'ogDescription', 'ogImage']),
		{ title: landing.ogTitle, description: landing.ogDescription }, // 추후 title에 대한 값이 들어올 경우, 처리 필요
	)
	useEffect(() => {
		if (status === 'ready') open(dialogMessages.ready)
		if (status === 'close') open(dialogMessages.close)
	}, [])

	return (
		<Container>
			<SEO {...metadata} />
			{status === 'open' &&
				sectionItems.map((section, idx) => {
					const imageSrc = device === 'pc' ? section.imageUrlPc : section.imageUrlMobile
					return (
						<button key={idx} onClick={() => handleSectionClick(section)}>
							<div
								style={{
									backgroundColor: section.bgColor,
								}}>
								<img src={imageSrc} alt={`랜딩 페이지 이미지 ${idx + 1}`} />
							</div>
						</button>
					)
				})}
			<ConfirmDialog {...dialogProps} />
			<CheckLoginDialog
				open={isLoginDialogOpen}
				onClose={closeLoginDialog}
				onClickLogin={(routePath) =>
					mixpanelEvent('[PR] Pop-up Button Clicked', {
						...mixpanelBase,
						'Popup Type': 'Login or Sign up',
						'Popup Title': '로그인이 필요해요',
						'Button Name': '로그인',
						'Button URL': routePath,
					})
				}
				onClickSignUp={(routePath) =>
					mixpanelEvent('[PR] Pop-up Button Clicked', {
						...mixpanelBase,
						'Popup Type': 'Login or Sign up',
						'Popup Title': '로그인이 필요해요',
						'Button Name': '회원가입',
						'Button URL': routePath,
					})
				}
			/>
		</Container>
	)
}

const Container = styled('div')(({ theme }) => ({
	button: {
		border: 0,
		background: 'none',
		padding: 0,
		width: '100%',
	},
	img: {
		maxWidth: '100%',
		[theme.breakpoints.up('sm')]: {
			maxWidth: 720,
		},
		[theme.breakpoints.up('lg')]: {
			maxWidth: 1200,
		},
	},
}))

export default Landing
