import { otherApiClient } from '.'

export interface LandingSectionItems {
	id: string
	type: string
	imageUrlPc: string
	imageUrlMobile: string
	bgColor: string
	imageLink: string
	buttonLink: string
}
export interface LandingDTO {
	id: string
	type: string
	ogUrl: string
	ogTitle: string
	keywords: string
	ogDescription: string
	ogImage: string
	status: string
	sectionItems: LandingSectionItems[]
	startedAt: string
	expiredAt: string
}

export interface LandingMembershipBody {
	sectionId: string
}

export interface LandingMembershipResponse {
	membershipProductId: number
	membershipProductTitle: string
}

export const getLanding = async (type: string, slug: string) => {
	const { data } = await otherApiClient.market.get<LandingDTO>(`/landing/${type}/${slug}`)
	return data
}

export const validateMembership = async (body: LandingMembershipBody) => {
	const { data } = await otherApiClient.market.post<LandingMembershipResponse>(
		`/landing/membership`,
		body,
	)
	return data
}
